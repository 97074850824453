"use client"

import { useMedia } from "react-use"

import { SCREENS } from "../styles"

export function useIsDesktop() {
  return useMedia(`(min-width: ${SCREENS.lg})`, false)
}

export function useIsMobile() {
  return useMedia(`(max-width: ${SCREENS.sm})`, false)
}
