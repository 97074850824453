"use client"

import "./feedbackForm.styles.css"

import { captureException } from "@sentry/nextjs"
import dynamic from "next/dynamic"
import { usePathname } from "next/navigation"

import { api } from "../../../app/trpc"
import { useIsMounted } from "../../../utils/hooks/useIsMounted"
import { useIsMobile } from "../../../utils/hooks/useMedia"

const Sidetab = dynamic(
  () =>
    import("@typeform/embed-react").then((module) => ({
      default: module.Sidetab,
    })),
  {
    ssr: false,
  },
)

const typeformBaseConfig = {
  id: "A8jvzqYZ",
  buttonText: "Feedback",
  // TODO nějakej jednotnej set barviček,
  //  co by se dal využít v tw i jako JS
  buttonColor: "#00515f",
  autoClose: true,
  customIcon: `https://cdn.deltagreen.cz/assets/proteus/typeform-feedback-icon.png`,
}

function useTypeformConfig() {
  const isMobile = useIsMobile()
  if (isMobile) {
    return {
      ...typeformBaseConfig,
      buttonWidth: 30,
      buttonTextSize: 14,
    }
  }

  return typeformBaseConfig
}

type Props = {
  userId: string | null
}

export function FeedbackForm(props: Props) {
  const isMounted = useIsMounted()
  if (!isMounted) {
    return null
  }

  if (props.userId) {
    return <AuthenticatedFeedbackForm {...props} />
  } else {
    return <UnauthenticatedFeedbackForm />
  }
}

function AuthenticatedFeedbackForm(props: Props) {
  const pathname = usePathname()
  const inverterList = api.inverters.list.useQuery()
  const config = useTypeformConfig()

  if (inverterList.isLoading) {
    return null
  }

  if (inverterList.isError) {
    captureException(inverterList.error)
    return null
  }

  return (
    <Sidetab
      {...config}
      hidden={{
        url: pathname,
        user_id: props.userId,
        inverters: JSON.stringify(inverterList.data),
      }}
    />
  )
}

function UnauthenticatedFeedbackForm() {
  const pathname = usePathname()
  const config = useTypeformConfig()

  return (
    <Sidetab
      {...config}
      hidden={{
        url: pathname,
      }}
    />
  )
}
